import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../Resources/Images/logo.png'
import Gplay from '../../Resources/Images/gplay_white.svg'
import AppStore from '../../Resources/Images/appStore_white.svg'
import s from './Footer.module.scss';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className={s.footer}>
            <div className={s.footerContent}>
                <div>{t('description_MagicChain')}</div>
                <div>
                    <div className={s.logo}>
                        <a href="https://magicchain.games/"><img src={Logo} alt="" /></a>
                    </div>
                    <div className={s.footerRight}>
                        <div className={s.contacts} dangerouslySetInnerHTML={{__html: t('requisites')}} />
                        <div className={s.offer}><a href={`/${t('offer_pdf')}`} target="_blank" rel="noreferrer">{t('offer')}</a></div>
                    </div>
                </div>
                <div className={s.appSection}>
                    <a href="https://play.google.com/store/apps/details?id=com.Nordavind.MagicChain" target="_blank" rel="noreferrer"><img src={Gplay} alt="" /></a>
                    <a href="https://apps.apple.com/ru/app/magicchain-for-you/id1488420660?l=ru" target="_blank" rel="noreferrer"><img src={AppStore } alt="" /></a>
                </div>
            </div>
        </footer>
    );
}

export default Footer