const goods = [
    {
        id: 2,
        name: {
            en: 'Bag of coins',
            ru: 'Мешок монет'
        },
        description: {
            en: 'Contains 50 000 coins',
            ru: 'Содержит 50 000 монет'
        },
        price: {
            usd: 2.99
        },
        image: 'good_2.png'
    },
    {
        id: 3,
        name: {
            en: 'Chest of coins',
            ru: 'Cундук монет'
        },
        description: {
            en: 'Contains 250 000 coins',
            ru: 'Содержит 250 000 монет'
        },
        price: {
            usd: 7.99 
        },
        image: 'good_3.png'
    },
    {
        id: 6,
        name: {
            en: 'Mountain of coins',
            ru: 'Гора монет'
        },
        description: {
            en: 'Contains 1 000 000 coins',
            ru: 'Содержит 1 000 000 монет'
        },
        price: {
            usd: 39.99 
        },
        image: 'good_6.png'
    },
    {
        id: 9,
        name: {
            en: 'Few crystals',
            ru: 'Несколько кристаллов'
        },
        description: {
            en: 'Contains 5 crystals',
            ru: 'Содержит 5 кристаллов'
        },
        price: {
            usd: 1
        },
        image: 'good_9.jpg'
    },
    {
        id: 10,
        name: {
            en: 'Bag of crystals',
            ru: 'Мешок кристаллов'
        },
        description: {
            en: 'Contains 25 crystals',
            ru: 'Содержит 25 кристаллов'
        },
        price: {
            usd: 4
        },
        image: 'good_10.jpg'
    },
    {
        id: 11,
        name: {
            en: 'Chest of crystals',
            ru: 'Сундук кристаллов'
        },
        description: {
            en: 'Contains 100 crystals',
            ru: 'Содержит 100 кристаллов'
        },
        price: {
            usd: 12
        },
        image: 'good_11.jpg'
    },
    {
        id: 101,
        name: {
            en: 'Handful of LIXI coins',
            ru: 'Горсть монет LIXI'
        },
        description: {
            en: 'Contains 100coins',
            ru: 'Содержит 100 монет'
        },
        price: {
            usd: 1
        },
        image: 'good_101.png'
    },
    {
        id: 102,
        name: {
            en: 'Bag of LIXI coins',
            ru: 'Мешок монет LIXI'
        },
        description: {
            en: 'Contains 1000 coins',
            ru: 'Содержит 1000 монет'
        },
        price: {
            usd: 10
        },
        image: 'good_102.png'
    },
    {
        id: 103,
        name: {
            en: 'Chest of LIXI coins',
            ru: 'Сундук монет LIXI'
        },
        description: {
            en: 'Contains 10000 coins',
            ru: 'Содержит 10000 монет'
        },
        price: {
            usd: 100
        },
        image: 'good_103.png'
    },
    {
        id: 104,
        name: {
            en: 'Mountain of LIXI coins',
            ru: 'Гора монет LIXI'
        },
        description: {
            en: 'Contains 100000 coins',
            ru: 'Содержит 100000 монет'
        },
        price: {
            usd: 1000
        },
        image: 'good_104.png'
    },  
]

export default goods;
