import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import ReCAPTCHA from '../../../Components/ReCAPTCHA/ReCAPTCHA';
import s from './BuyDialog.module.scss';
import * as api  from '../../../Api';
import { purchaseDataLayer } from '../../../Utils/yandexEcommerce'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BuyDialog = props => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const { good } = props;

    const [open, setOpen] = useState(false);
    const [login, setLogin] = useState('');
    const [recaptcha, setRecaptcha] = useState(false);
    const [lockRecaptcha, setlockRecaptcha] = useState(true);
    const [hideCaptcha, setHideCaptcha] = useState(false);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({type: 'info', content: ''});
    const [disabledBtnSubmit, setDisabledBtnSubmit] = useState(false);

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const handleClickOpen = () => {
        purchaseDataLayer(good);
        setOpen(true);
        setHideCaptcha(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogin = obj => {
        const login = obj.target.value;
        setLogin(login);
    }

    const onChange = (value) => {
        setRecaptcha(value);
    }

    const validLogin = login => {
        return /^.+@.+\..+$/igm.test(login);
    }

    const language = i18n.language.split('-')[0];

    const handleSubmitform = event => {
        event.preventDefault();
        setDisabledBtnSubmit(true);
        setOpenAlert(true);
        setAlertMessage({type: 'success', content: t('sending')});
        setHideCaptcha(true);

        api.getReferralId(login)
            .then( data => {
                if (data.success === true) {
                    api.purchase(data.referralId, good.id, language, t('currency'))
                        .then( data => {
                            if (data.success === true) {
                                setOpenAlert(true);
                                setAlertMessage({type: 'success', content: t('you_will_be_directed')});
                                document.location.href = data.url;
                            } else {
                                setOpenAlert(true);
                                setAlertMessage({type: 'error', content: t('error sending')});
                            }
                            setDisabledBtnSubmit(false);
                        })
                } else {
                    setOpenAlert(true);
                    setAlertMessage({type: 'warning', content: t('login_is_not_registered')});
                    setDisabledBtnSubmit(false);
                }
            })
    }

    const inputLoginOnBlur = () => {
        setlockRecaptcha(false);
    }

    const inputLoginOnFocus = () => {
        setlockRecaptcha(true);
    }

    return (
        <div>
            <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertMessage.type}>
                    {alertMessage.content}
                </Alert>
            </Snackbar>

            <Button color="primary" variant="contained" onClick={handleClickOpen}>
                {t('buy')}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                {disabledBtnSubmit ? <div className={s.progressLoader}><CircularProgress disableShrink /></div> : null}
                <DialogTitle id="form-dialog-title">{good.name[language]}</DialogTitle>
                <form className={s.buy} onSubmit={handleSubmitform}>
                    <DialogContent>
                        <DialogContentText>
                            {good.description[language]}
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="price"
                            label={`${t('price')} ${t('valuta')}`}
                            InputProps={{
                                value: Math.round(good.price.usd * t('rate') * 100) / 100,
                                readOnly: true,
                            }}
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="login"
                            label={t('login_MagicChain')}
                            fullWidth
                            onChange={handleLogin}
                            onBlur={inputLoginOnBlur}
                            onFocus={inputLoginOnFocus}
                            value={login}
                            inputProps={{pattern:"^.+@.+\\..+$"}}
                        />
                        <div className={ lockRecaptcha ? s.lockRecaptcha : null}>
                            {hideCaptcha ? null : <ReCAPTCHA onChange={onChange} disabled="true"/>}
                        </div>
                    </DialogContent>
                    
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined">
                            {t('cancel')}
                        </Button>
                        <Button type="submit" color="primary" variant="contained" disabled={!recaptcha || !validLogin(login) || disabledBtnSubmit}>
                            {t('buy')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default BuyDialog;