import React from 'react';
import { useTranslation } from 'react-i18next';
import s from './Good.module.scss';
import BuyDialog from './BuyDialog/BuyDialog';

const Good = props => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const { good } = props;
    const language = i18n.language.split('-')[0];

    return (
        <div className={s.good}>
            <div style={{backgroundImage: `url(/images/${good.image}`}}className={s.image} />
            <div className={s.ndb}>
                <div className={s.note}>
                    <p className={s.name}>{good.name[language]}</p>
                    <p className={s.description}>{good.description[language]}</p>
                </div>
                <div className={s.buy}>
                    <BuyDialog
                        good={good}
                    />
                </div>
            </div>
            <p className={s.price}>{Math.round(good.price.usd * t('rate') * 100) / 100}  {t('valuta')}</p>
        </div>
    )
}

export default Good