import * as React from 'react';
import ReCAPTCHAComponent from 'react-google-recaptcha';
import i18n from 'i18next';
import s from './ReCAPTCHA.module.scss';

const ReCAPTCHA = props => {

  const recaptchaRef = React.createRef();

  return (
        <ReCAPTCHAComponent
          className={s.recaptcha}
          sitekey="6LcgM6gaAAAAAHILMUgMrnJ1SyLIEXZ2KPytlNHu"
          onChange={props.onChange}
          size="normal"
          theme='light'
          ref={recaptchaRef}
          hl={i18n.language}
        />
  );
}

export default ReCAPTCHA;
