const purchaseDataLayer = good => {
  window.dataLayerShop = window.dataLayerShop || [];
  window.dataLayerShop.push({
      "ecommerce": {
          "purchase": {
              "actionField": {
                  "id" : ""
              },
              "products": [
                  {
                      "id": good.id,
                      "name": good.name.ru,
                      "price": good.price.usd,
                      "brand": "",
                      "category": "",
                      "variant": ""
                  }
              ]
          }
      }
  });
}

export {
  purchaseDataLayer
}