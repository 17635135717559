import {
    FETCH_GOODS_SUCCESS
} from '../actionTypes';

const initialState = []

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case FETCH_GOODS_SUCCESS:
            return payload
        default:
            return state
    }
}