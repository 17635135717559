import {
    LANGUAGE_CHANGE
} from '../actionTypes';

const initialState = '';

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case LANGUAGE_CHANGE:
            return payload;
        default:
            return state;
    }
}
