import {
    FETCH_GOODS_START,
    FETCH_GOODS_SUCCESS,
    FETCH_GOODS_FAILURE,
    LANGUAGE_CHANGE
} from '../actionTypes'
import {fetchGoods as fetchGoodsApi} from '../Api/index.js'

export const fetchGoods = () => async dispatch => {
    dispatch({type: FETCH_GOODS_START})

    try {
        const goods = await fetchGoodsApi()
        dispatch({
            type: FETCH_GOODS_SUCCESS,
            payload: goods
        })
    } catch (err) {
        dispatch({
            type: FETCH_GOODS_FAILURE,
            payload: err,
            error: true
        })
    }
}

export const switchLanguage = lang => async dispatch => {
        dispatch({
            type: LANGUAGE_CHANGE,
            payload: lang
        })
}
