import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {fetchGoods} from '../../Actions'
import s from './Goods.module.scss';
import Loading from '../../Components/Loading';
import Good from '../Good/Good'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Goods = props => {
    const { t } = useTranslation();

    useEffect(() => {
        props.fetchGoods();
        switch(props.hash) {
        case '#paymentSuccess':
            setOpenAlert(true);
            setAlertMessage({type: 'success', content: t('payment_success')});
            break;
        case '#paymentError':
            setOpenAlert(true);
            setAlertMessage({type: 'error', content: t('payment_failed')});
            break;
        } 
    }, []);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({type: 'info', content: ''});

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        props.history.push('/');
    };

    const { goods } = props;

    if (!goods) return <Loading /> ;

    return (
        <div className={s.mainGoods}>
            <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertMessage.type}>
                    {alertMessage.content}
                </Alert>
            </Snackbar>
            <h1>{t('goods')}</h1>
            <div className={s.goodsSection}>{goods.map(good => <Good key={good.id} good={good}/>)}</div>
        </div>
    )
}

const mapStateToProps = state => ({
    goods: state.goods,
    hash: state.router.location.hash
});

const mapDispatchToProps = {
    fetchGoods
}

export default connect(mapStateToProps, mapDispatchToProps)(Goods);