import React  from 'react';
// import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Switch from '@material-ui/core/Switch';
import s from './LanguageSwitcher.module.scss';


const LanguageSwitcher = props => {

    // const { i18n } = useTranslation();

    const [state, setState] = React.useState({
        languageEn: i18n.language.split('-')[0] === 'en',
    });

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.checked ? 'en' : 'ru');
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <div className={s.languageSwitcher}>
            <span>ru</span>
            <Switch
                checked={state.languageEn}
                onChange={handleChange}
                name="languageEn"
                color="default"
                inputProps={{ 'aria-label': 'checkbox with default color' }}
            />
            <span>en</span>
        </div>
    );
}

export default LanguageSwitcher;