import goods from './mockGoods'

// const URL_API = 'http://store-test.magicchain.games';
const URL_API = 'https://store.magicchain.games';

export const fetchGoods = async () => {
    return new Promise(resolve => {
        resolve(goods)
    });
}

export const getReferralId = (email) => {

    const options = {
        method: 'POST',
        body: `email=${email}`,
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        }
    }

    return fetch(`${URL_API}/mcbuy/email.php`, options)
        .then(response => response.json());
}

export const purchase = (referralId, entityId, lang, currency) => {

    const data = `referralId=${referralId}&entityId=${entityId}&lang=${lang}&currency=${currency}`;

    const options = {
        method: 'POST',
        body: data,
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        }
    }

    return fetch(`${URL_API}/mcbuy/purchase.php`, options)
        .then(response => response.json());
}