import React from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MetaTags from 'react-meta-tags';

import s from './Header.module.scss';
import LanguageSwitcher from '../../Containers/LanguageSwitcher/LanguageSwitcher';


const Header = () => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return(
        <div className={s.header}>
            <MetaTags>
                <title>{t('title')}</title>
                <meta name="description" content={t('description')} />
                <meta name="keywords" content={t('keywords')} />
            </MetaTags>
            <AppBar position="fixed">
                <Toolbar variant="dense" className={s.menu}>
                    <a href="https://magicchain.games/" className={s.left}>MagicChain</a>
                    <div className={s.contacts}>
                        <div className={s.desktopMenu}>
                            <a href="tel:+74991309892">+7 (499) 130-98-92</ a>
                            <a href="mailto:support@magicchain.games">support@magicchain.games</a>
                            <LanguageSwitcher />
                        </div>
                        <div className={s.mobileMenu}>
                            <IconButton edge="start" color="inherit" aria-label="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                className={s.mobileMenuView}
                            >
                                <MenuItem onClick={handleClose}><a href="tel:+74991309892">+7 (499) 130-98-92</ a></MenuItem>
                                <MenuItem onClick={handleClose}><a href="mailto:support@magicchain.games">support@magicchain.games</a></MenuItem>
                                <MenuItem onClick={handleClose}><LanguageSwitcher /></MenuItem>
                            </Menu>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header