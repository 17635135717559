import React from 'react'
import {Switch, Route, Redirect} from 'react-router'

import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'
import Googs from './Containers/Goods/Goods'
import './index.scss'
import './Locale/i18n';

export default (
  <>
    <Header />
    <Switch>
      <Route path='/' component={Googs} exact />
      <Redirect to='/' component={Googs}/>
    </Switch>
    <Footer />
  </>
)
