import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'

import goods from './goods'
import language from './language'

export default history => combineReducers({
    router: connectRouter(history),
    goods,
    language
  })
